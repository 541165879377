// import React from "react";
// import { useTranslation } from "react-i18next";
// import { motion } from "framer-motion";

// const Biography = () => {
//   const { t } = useTranslation();

//   return (
//     <div className="pt-24">
//       <motion.div
//         initial={{ opacity: 0, y: 20 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.5 }}
//         className="max-w-4xl mx-auto"
//       >
//         <h1 className="page-title text-center">{t("biography.title")}</h1>
//         <div className="grid md:grid-cols-2 gap-8 items-start">
//           <div>
//             <img
//               src="/images/rr117.jpg"
//               alt="Ramon Riera"
//               className="rounded-lg shadow-xl"
//             />
//           </div>
//           <div className="space-y-6 text-light/80">
//             <p>
//               Considerado uno de los mejores músicos del país Ramon Riera es un
//               artista enérgico, versátil e innovador. Toca Saxofón, Flauta
//               Travesera, Vocoder (voz electrónica), EWI (saxo electrónico) e
//               instrumentos étnicos de viento (como la Dolçaina, el Ney o el
//               Bansury).{" "}
//             </p>
//             <p>
//               Su directo es fresco, vivo, elaborado y lleno de energía y
//               electricidad, sobretodo, gracias a su luminoso saxo con LED
//               haciendo que el público se entregue por completo a su show y a su
//               calidad musical que este fantástico músico derrocha en cada
//               actuación.{" "}
//             </p>
//             <p>
//               Ha tocado en lugares tan famosos y reconocidos mundialmente como:
//               ‍ Pacha (Ibiza), Space (Ibiza), Hed Kandi (Ibiza), Eden (Ibiza),
//               Es Paradís (Ibiza), Isla Marina (Alicante), Marmarela (Alicante),
//               Macao (Alicante), Jauja (Alicante), Titos (Mallorca), Nikki Beach
//               (Mallorca), Pacha (Valencia), Mae West (Granada), Mandala
//               (Almería), Kube (Madrid), Joy (Madrid), Contra Bar (Noruega),
//               Beach Party Cocoloco (Punta Cana), Wahm (Doha).{" "}
//             </p>
//           </div>
//         </div>
//       </motion.div>
//     </div>
//   );
// };

// export default Biography;

import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Biography = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto px-4"
      >
        <h1 className="page-title text-center">{t("biography.title")}</h1>
        <div className="grid md:grid-cols-2 gap-8 items-start">
          <div>
            <img
              src="/images/rr117.jpg"
              alt="Ramon Riera"
              className="rounded-lg shadow-xl w-full"
            />
          </div>
          <div className="space-y-6 text-light/80">
            {t("biography.content").split("\n\n").map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Biography;
