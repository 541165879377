import React from 'react';
import saxophoneImage from '../../../public/images/saxophone.png';

const SaxophoneIcon = ({ className = "w-8 h-8" }: { className?: string }) => (
  <img 
    src={saxophoneImage}
    alt="Saxophone"
    className={`${className} [filter:invert(65%)_sepia(12%)_saturate(1011%)_hue-rotate(7deg)_brightness(89%)_contrast(84%)]`}
  />
);

export default SaxophoneIcon;