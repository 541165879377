export default {
  nav: {
    home: 'Inicio',
    biography: 'Biografía',
    events: 'Eventos',
    music: 'Música',
    gallery: 'Galería',
    contact: 'Contacto'
  },
  home: {
    hero: {
      subtitle: 'Saxofonista, DJ & Productor Musical',
      cta: 'Descubre mis servicios'
    },
    services: {
      title: 'Servicios',
      saxophone: {
        title: 'Saxofonista',
        description: 'Actuaciones en directo con saxofón para bodas, eventos corporativos y fiestas exclusivas.'
      },
      dj: {
        title: 'DJ',
        description: 'Sets únicos que combinan música electrónica con actuaciones en directo de saxofón.'
      },
      producer: {
        title: 'Productor Musical',
        description: 'Producción de música electrónica y colaboraciones con artistas internacionales.'
      }
    },
    about: {
      title: 'Sobre mí',
      description: 'Con más de una década de experiencia, fusiono la elegancia del saxofón con la energía de la música electrónica para crear experiencias únicas.',
      cta: 'Conoce mi historia'
    }
  },
  biography: {
    title: 'Biografía',
    content: 'Ramon Riera es un artista versátil que ha cautivado a audiencias en todo el mundo con su única fusión de saxofón y música electrónica. Con más de una década de experiencia en la industria musical, Ramon ha desarrollado un estilo distintivo que combina la elegancia del saxofón clásico con ritmos electrónicos contemporáneos.\n\nFormado en el Conservatori del Liceu de Barcelona y habiendo perfeccionado su técnica en prestigiosas escuelas internacionales, Ramon ha llevado su música a algunos de los lugares más exclusivos de Europa. Su capacidad para adaptar su actuación a diferentes contextos, desde bodas de lujo hasta eventos corporativos de alto nivel y clubes internacionales, lo ha convertido en uno de los artistas más solicitados en el sector de eventos premium.\n\nComo productor musical, sus tracks han sido apoyados por algunos de los DJs más respetados de la escena, y sus lanzamientos en sellos destacados han alcanzado las listas de éxitos en plataformas como Beatport. Su trabajo en el estudio se caracteriza por una meticulosa atención al detalle y una innovadora integración de instrumentos acústicos en producciones electrónicas.\n\nActualmente, Ramon divide su tiempo entre actuaciones en directo, sesiones de DJ con saxofón en vivo y producción musical en su estudio personal, donde continúa explorando nuevas formas de fusionar géneros y crear experiencias musicales únicas.'
  },
  events: {
    title: 'Próximos Eventos',
    noEvents: 'No hay eventos programados próximamente'
  },
  music: {
    title: 'Mi Música',
    platforms: {
      spotify: 'Escucha en Spotify',
      beatport: 'Compra en Beatport',
      traxsource: 'Compra en Traxsource'
    }
  },
  contact: {
    title: 'Contacto',
    subtitle: 'Reserva tu fecha o solicita información',
    form: {
      name: 'Nombre',
      email: 'Email',
      message: 'Mensaje',
      submit: 'Enviar mensaje',
      emailError: 'Por favor, introduce un email válido',
      success: 'Mensaje enviado correctamente',
      error: 'Error al enviar el mensaje. Por favor, inténtalo de nuevo.',
      captchaError: 'Por favor, completa el captcha'
    }
  }
};