import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './en';
import esTranslations from './es';
import deTranslations from './de';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    es: { translation: esTranslations },
    de: { translation: deTranslations }
  },
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false
  }
});