import React from 'react';
import beatportImage from '../../../public/images/logoBeatport.png';

const BeatportIcon = ({ className = "w-8 h-8" }: { className?: string }) => (
  <img 
    src={beatportImage}
    alt="Beatport"
    className={`${className} [filter:invert(65%)_sepia(12%)_saturate(1011%)_hue-rotate(7deg)_brightness(180%)_contrast(84%)]`}
  />
);

export default BeatportIcon;