import React from 'react';
import { useTranslation } from 'react-i18next';
import { Instagram, Facebook, Mail } from 'lucide-react';
import Logo from './Logo';
import { img } from 'framer-motion/client';
import SpotifyIcon from './icons/SpotifyIcon';
import YouTubeIcon from './icons/YoutubeIcon';

const Footer = () => {
  const { t } = useTranslation();

  const socialLinks = [
    { icon: <Instagram size={20} />, href: 'https://www.instagram.com/ramonrieramusic/', label: 'Instagram' },
    { icon: <Facebook size={20} />, href: 'https://www.facebook.com/ramonrieramusic/', label: 'Facebook' },
    { icon: <SpotifyIcon size={20} />, href: 'https://open.spotify.com/intl-es/artist/0rU59FhgKyOAIZXq9wX38n?si=K_LQZal6Q4yJpeBL86IyFA', label: 'Spotify' },
    { icon: <YouTubeIcon size={20} />, href: 'https://www.youtube.com/ramonrieramusic', label: 'YouTube' },
    { icon: <Mail size={20} />, href: 'mailto:info@ramonriera.es', label: 'Email' },
  ];

  return (
    <footer className="bg-dark border-t border-gold/20">
      <div className="container mx-auto px-4 py-4">
        <div className="flex flex-col items-center md:flex-row md:justify-between gap-4">
          <div className="w-48">
            <Logo />
          </div>
          <div className="flex items-center space-x-6">
            {socialLinks.map((link) => (
              <a
                key={link.label}
                href={link.href}
                className="text-light hover:text-gold transition-colors"
                aria-label={link.label}
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>
        <div className="text-center text-light/60 text-sm mt-2">
          © {new Date().getFullYear()} Ramon Riera. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;