export default {
  nav: {
    home: 'Startseite',
    biography: 'Biografie',
    events: 'Events',
    music: 'Musik',
    gallery: 'Galerie',
    contact: 'Kontakt'
  },
  home: {
    hero: {
      subtitle: 'Saxophonist, DJ & Musikproduzent',
      cta: 'Entdecke meine Dienstleistungen'
    },
    services: {
      title: 'Dienstleistungen',
      saxophone: {
        title: 'Saxophonist',
        description: 'Live-Saxophon-Auftritte für Hochzeiten, Firmenveranstaltungen und exklusive Partys.'
      },
      dj: {
        title: 'DJ',
        description: 'Einzigartige Sets, die elektronische Musik mit Live-Saxophon-Auftritten kombinieren.'
      },
      producer: {
        title: 'Musikproduzent',
        description: 'Produktion elektronischer Musik und Zusammenarbeit mit internationalen Künstlern.'
      }
    },
    about: {
      title: 'Über mich',
      description: 'Mit über einem Jahrzehnt Erfahrung verbinde ich die Eleganz des Saxophons mit der Energie elektronischer Musik.',
      cta: 'Meine Geschichte'
    }
  },
  biography: {
    title: 'Biografie',
    content: 'Ramon Riera ist ein vielseitiger Künstler, der weltweit das Publikum mit seiner einzigartigen Fusion aus Saxophon und elektronischer Musik begeistert. Mit über einem Jahrzehnt Erfahrung in der Musikindustrie hat Ramon einen unverwechselbaren Stil entwickelt, der die Eleganz des klassischen Saxophons mit zeitgenössischen elektronischen Rhythmen verbindet.\n\nAusgebildet am Conservatori del Liceu in Barcelona und nach Perfektionierung seiner Technik an renommierten internationalen Schulen hat Ramon seine Musik zu einigen der exklusivsten Veranstaltungsorte Europas gebracht. Seine Fähigkeit, seine Performance an verschiedene Kontexte anzupassen, von Luxushochzeiten über hochkarätige Firmenveranstaltungen bis hin zu internationalen Clubs, hat ihn zu einem der gefragtesten Künstler im Premium-Eventbereich gemacht.\n\nAls Musikproduzent wurden seine Tracks von einigen der angesehensten DJs der Szene unterstützt, und seine Veröffentlichungen auf namhaften Labels haben die Charts auf Plattformen wie Beatport erreicht. Seine Studioarbeit zeichnet sich durch akribische Detailgenauigkeit und innovative Integration akustischer Instrumente in elektronischen Produktionen aus.\n\nAktuell teilt Ramon seine Zeit zwischen Live-Auftritten, DJ-Sets mit Live-Saxophon und Musikproduktion in seinem persönlichen Studio, wo er weiterhin neue Wege der Genrefusion erkundet und einzigartige musikalische Erlebnisse schafft.'
    },
  events: {
    title: 'Kommende Events',
    noEvents: 'Keine kommenden Events geplant'
  },
  music: {
    title: 'Meine Musik',
    platforms: {
      spotify: 'Auf Spotify hören',
      beatport: 'Auf Beatport kaufen',
      traxsource: 'Auf Traxsource kaufen'
    }
  },
  contact: {
    title: 'Kontakt',
    subtitle: 'Buchen Sie Ihren Termin oder fordern Sie Informationen an',
    form: {
      name: 'Name',
      email: 'E-Mail',
      message: 'Nachricht',
      submit: 'Nachricht senden',
      emailError: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
      success: 'Nachricht erfolgreich gesendet',
      error: 'Fehler beim Senden der Nachricht. Bitte versuchen Sie es erneut.',
      captchaError: 'Bitte füllen Sie das Captcha aus'
    }
  }
};