import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Disc3, Music4, Waves } from 'lucide-react';

const DJ = () => {
  const { t } = useTranslation();

  const styles = [
    { icon: <Waves className="w-6 h-6" />, title: 'House & Deep House', description: 'Ritmos elegantes y atmosféricos perfectos para crear el ambiente adecuado.' },
    { icon: <Music4 className="w-6 h-6" />, title: 'Tech House & Melodic', description: 'Sonidos modernos y envolventes que mantienen la pista de baile activa.' },
    { icon: <Disc3 className="w-6 h-6" />, title: 'Comercial & Charts', description: 'Los éxitos más actuales mezclados con profesionalidad y estilo.' }
  ];

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-6xl mx-auto px-4"
      >
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <h1 className="text-4xl md:text-5xl font-display text-gold mb-6">DJ Profesional</h1>
            <p className="text-light/80 text-lg leading-relaxed mb-8">
              Sets únicos que fusionan música electrónica de calidad con actuaciones en directo de saxofón, creando una experiencia musical inmersiva y sofisticada para tu evento.
            </p>
            <a
              href="/contact"
              className="inline-block px-8 py-3 bg-gold text-dark font-semibold rounded-full hover:bg-gold/90 transition-colors"
            >
              Reserva tu fecha
            </a>
          </div>
          <div>
            <img
              src="/images/rrdj01.jpeg"
              alt="DJ Set"
              className="rounded-lg shadow-xl"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {styles.map((style, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-light/5 p-6 rounded-lg border border-gold/20"
            >
              <div className="text-gold mb-4">{style.icon}</div>
              <h3 className="text-xl font-display text-gold mb-2">{style.title}</h3>
              <p className="text-light/80">{style.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="bg-light/5 rounded-lg p-8 border border-gold/20">
          <h2 className="text-3xl font-display text-gold mb-6">Equipamiento Profesional</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-display text-gold mb-4">Equipo de Sonido</h3>
              <ul className="space-y-2 text-light/80">
                <li>• Sistema de sonido profesional</li>
                <li>• Monitores de alta calidad</li>
                <li>• Mezcladores Pioneer DJ</li>
                <li>• Microfonía profesional</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-display text-gold mb-4">Iluminación</h3>
              <ul className="space-y-2 text-light/80">
                <li>• Sistema de luces LED</li>
                <li>• Efectos especiales</li>
                <li>• Máquina de humo</li>
                <li>• Iluminación ambiental</li>
              </ul>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default DJ;