import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Disc, Music } from "lucide-react";
import { Link } from "react-router-dom";
import HeroSection from "../components/HeroSection";
import SaxophoneIcon from "../components/icons/SaxophoneIcon";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="space-y-20 pt-20">
      <HeroSection />

      {/* Services Section */}
      <section id="services" className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-display text-gold text-center mb-12">
          {t("home.services.title")}
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          <ServiceCard
            icon={<SaxophoneIcon className="w-8 h-8" />}
            title={t("home.services.saxophone.title")}
            description={t("home.services.saxophone.description")}
            link="/services/saxophone"
          />
          <ServiceCard
            icon={<Disc className="w-8 h-8" />}
            title={t("home.services.dj.title")}
            description={t("home.services.dj.description")}
            link="/services/dj"
          />
          <ServiceCard
            icon={<Music className="w-8 h-8" />}
            title={t("home.services.producer.title")}
            description={t("home.services.producer.description")}
            link="/services/producer"
          />
        </div>
      </section>

      {/* About Preview Section */}
      <section className="bg-light/5 py-20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="h-[250px] overflow-hidden rounded-lg shadow-xl">
              <img
                src="/images/rr309.jpg"
                alt="Ramon Riera performing"
                className="w-full h-full object-cover object-[center_10%]"
              />
            </div>
            <div className="space-y-6">
              <h2 className="text-3xl md:text-4xl font-display text-gold">
                {t("home.about.title")}
              </h2>
              <p className="text-light/80 leading-relaxed">
                {t("home.about.description")}
              </p>
              <Link
                to="/biography"
                className="inline-block px-6 py-2 border-2 border-gold text-gold hover:bg-gold hover:text-dark transition-colors rounded-full"
              >
                {t("home.about.cta")}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const ServiceCard = ({
  icon,
  title,
  description,
  link,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
  link: string;
}) => (
  <Link to={link}>
    <motion.div
      whileHover={{ scale: 1.03 }}
      className="p-6 rounded-lg bg-light/5 border border-gold/20 hover:border-gold/40 transition-colors h-full"
    >
      <div className="text-gold mb-4">{icon}</div>
      <h3 className="text-xl font-display text-gold mb-2">{title}</h3>
      <p className="text-light/80">{description}</p>
    </motion.div>
  </Link>
);

export default Home;
