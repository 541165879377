import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import centerLogo from '../assets/center-logo.png';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section className="relative h-[90vh] flex items-center justify-center">
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?q=80&w=2070')] bg-cover bg-center">
        <div className="absolute inset-0 bg-dark/70"></div>
      </div>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="relative text-center space-y-6 max-w-4xl mx-auto px-4"
      >
        <div className="flex justify-center mb-8">
          <img 
            src={centerLogo}
            alt="Ramon Riera Music"
            className="w-[1000px] md:w-[1200px] h-auto"
          />
        </div>
        <p className="text-xl md:text-2xl text-light/90">
          {t('home.hero.subtitle')}
        </p>
        <div className="flex justify-center gap-4">
          <a
            href="#services"
            className="px-8 py-3 bg-gold text-dark font-semibold rounded-full hover:bg-gold/90 transition-colors"
          >
            {t('home.hero.cta')}
          </a>
        </div>
      </motion.div>
    </section>
  );
};

export default HeroSection;