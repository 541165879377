import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Send } from 'lucide-react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const EMAILJS_SERVICE_ID = 'service_8b9l3u7';
const EMAILJS_TEMPLATE_ID = 'template_9d5k79a';
const EMAILJS_PUBLIC_KEY = '3vAyEbV6_nbJhmpHq';
const RECAPTCHA_SITE_KEY = '6LegV5QqAAAAAAGVqK8HIIaBWXntnXCgYEh7ExHj';

const Contact = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [status, setStatus] = React.useState<'idle' | 'success' | 'error'>('idle');
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(null);
  const [emailError, setEmailError] = React.useState(false);
  const formRef = React.useRef<HTMLFormElement>(null);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (email === '' || validateEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const emailInput = formRef.current?.querySelector('input[type="email"]') as HTMLInputElement;
    if (!validateEmail(emailInput.value)) {
      setEmailError(true);
      return;
    }

    if (!captchaToken) {
      alert('Por favor, completa el captcha');
      return;
    }

    setIsSubmitting(true);
    setStatus('idle');

    try {
      await emailjs.sendForm(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        formRef.current!,
        EMAILJS_PUBLIC_KEY
      );
      setStatus('success');
      if (formRef.current) formRef.current.reset();
      if (recaptchaRef.current) recaptchaRef.current.reset();
      setCaptchaToken(null);
      setEmailError(false);
    } catch (error) {
      setStatus('error');
      console.error('Error sending email:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-2xl mx-auto px-4"
      >
        <h1 className="page-title text-center">{t('contact.title')}</h1>
        
        <div className="mb-8 text-center text-light/80">
          <p>Email: info@ramonriera.es</p>
        </div>

        <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
          <input type="hidden" name="to_email" value="info@ramonriera.es" />
          
          <div>
            <label htmlFor="name" className="block text-light/80 mb-2">
              {t('contact.form.name')}
            </label>
            <input
              type="text"
              id="name"
              name="from_name"
              required
              className="w-full px-4 py-2 bg-light/5 border border-gold/20 rounded-lg focus:border-gold/40 focus:outline-none text-light"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-light/80 mb-2">
              {t('contact.form.email')}
            </label>
            <input
              type="email"
              id="email"
              name="reply_to"
              required
              onChange={handleEmailChange}
              className={`w-full px-4 py-2 bg-light/5 border ${
                emailError ? 'border-red-500' : 'border-gold/20'
              } rounded-lg focus:border-gold/40 focus:outline-none text-light`}
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1">
                {t('contact.form.emailError')}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="message" className="block text-light/80 mb-2">
              {t('contact.form.message')}
            </label>
            <textarea
              id="message"
              name="message"
              required
              rows={6}
              className="w-full px-4 py-2 bg-light/5 border border-gold/20 rounded-lg focus:border-gold/40 focus:outline-none text-light resize-none"
            ></textarea>
          </div>

          <div className="flex justify-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleCaptchaChange}
              theme="dark"
            />
          </div>

          {status === 'success' && (
            <div className="text-green-500 text-center">
              Mensaje enviado correctamente a info@ramonriera.es
            </div>
          )}

          {status === 'error' && (
            <div className="text-red-500 text-center">
              Error al enviar el mensaje. Por favor, inténtalo de nuevo.
            </div>
          )}

          <button
            type="submit"
            disabled={isSubmitting || !captchaToken || emailError}
            className="w-full px-8 py-3 bg-gold text-dark font-semibold rounded-lg hover:bg-gold/90 transition-colors flex items-center justify-center gap-2 disabled:opacity-50"
          >
            {isSubmitting ? (
              'Enviando...'
            ) : (
              <>
                {t('contact.form.submit')}
                <Send size={18} />
              </>
            )}
          </button>
        </form>
      </motion.div>
    </div>
  );
};

export default Contact;