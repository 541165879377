import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Biography from './pages/Biography';
import Events from './pages/Events';
import Music from './pages/Music';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Saxophone from './pages/services/Saxophone';
import DJ from './pages/services/DJ';
import Producer from './pages/services/Producer';
import './i18n/config';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="biography" element={<Biography />} />
          <Route path="events" element={<Events />} />
          <Route path="music" element={<Music />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="contact" element={<Contact />} />
          <Route path="services/saxophone" element={<Saxophone />} />
          <Route path="services/dj" element={<DJ />} />
          <Route path="services/producer" element={<Producer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;