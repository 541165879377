import React from 'react';

const SpotifyIcon = ({ size = 20 }: { size?: number }) => (
  <svg 
    width={size} 
    height={size} 
    viewBox="0 0 24 24" 
    fill="currentColor"
    // className="text-gold hover:text-light transition-colors"
  >
    <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.371-.721.49-1.101.241-3.021-1.858-6.832-2.278-11.322-1.247-.422.097-.851-.162-.949-.584-.098-.422.162-.851.584-.949 4.91-1.121 9.081-.652 12.452 1.391.369.239.489.72.24 1.101zm1.472-3.279c-.301.468-.947.615-1.413.309-3.462-2.139-8.736-2.759-12.834-1.511-.533.163-1.095-.144-1.259-.679-.162-.533.144-1.095.679-1.259 4.676-1.418 10.471-.72 14.405 1.728.467.29.615.934.308 1.401zm.126-3.409C15.17 8.384 8.807 8.162 5.129 9.29c-.639.197-1.312-.158-1.508-.794-.197-.639.158-1.312.794-1.509 4.218-1.281 11.223-1.033 15.656 1.595.559.338.736 1.067.396 1.625-.338.559-1.067.736-1.625.396z"/>
  </svg>
);

export default SpotifyIcon;
