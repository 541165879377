import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const Gallery = () => {
  const { t } = useTranslation();

  const images = [
    {
      url: '/images/rr117.jpg',
      caption: 'Live Performance'
    },
    {
      url: '/images/rr309.jpg',
      caption: 'Studio Session'
    },
    {
      url: '/images/rrdj01.jpeg',
      caption: 'DJ Set'
    }
  ];

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="page-title text-center">Galería</h1>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {images.map((image, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group relative aspect-square overflow-hidden rounded-lg"
            >
              <img
                src={image.url}
                alt={image.caption}
                className="object-cover w-full h-full transform group-hover:scale-110 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-dark/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                <p className="text-light font-display text-xl">{image.caption}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Gallery;