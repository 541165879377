import React from 'react';
import traxsourceImage from '../../../public/images/logoTraxsource.png';

const TraxsourceIcon = ({ className = "w-8 h-8" }: { className?: string }) => (
  <img 
    src={traxsourceImage}
    alt="Traxsource"
    className={`${className} [filter:invert(65%)_sepia(20%)_saturate(1011%)_hue-rotate(7deg)_brightness(100%)_contrast(70%)]`}
  />
);

export default TraxsourceIcon;