import React from 'react';
import { Link } from 'react-router-dom';
import logoImage from '../assets/logo.png';

const Logo = () => {
  return (
    <Link to="/" className="block">
      <img 
        src={logoImage}
        alt="Ramon Riera Music" 
        className="h-1000 md:h-160"
      />
    </Link>
  );
};

export default Logo;