import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Mic2, PartyPopper, Building2 } from 'lucide-react';

const Saxophone = () => {
  const { t } = useTranslation();

  const events = [
    { icon: <PartyPopper className="w-6 h-6" />, title: 'Bodas de lujo', description: 'Actuaciones elegantes que aportan distinción y clase a tu ceremonia y celebración.' },
    { icon: <Building2 className="w-6 h-6" />, title: 'Eventos corporativos', description: 'Ambiente sofisticado para eventos empresariales, inauguraciones y galas.' },
    { icon: <Mic2 className="w-6 h-6" />, title: 'Fiestas privadas', description: 'Experiencias musicales únicas para celebraciones exclusivas.' }
  ];

  return (
    <div className="pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-6xl mx-auto px-4"
      >
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <h1 className="text-4xl md:text-5xl font-display text-gold mb-6">Saxofonista Profesional</h1>
            <p className="text-light/80 text-lg leading-relaxed mb-8">
              Eleva tu evento a otro nivel con actuaciones en directo de saxofón que combinan elegancia clásica con energía contemporánea. Una experiencia musical única que cautivará a tus invitados.
            </p>
            <a
              href="/contact"
              className="inline-block px-8 py-3 bg-gold text-dark font-semibold rounded-full hover:bg-gold/90 transition-colors"
            >
              Reserva tu fecha
            </a>
          </div>
          <div>
            <img
              src="/images/rr214.jpg"
              alt="Saxofonista en directo"
              className="rounded-lg shadow-xl"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {events.map((event, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-light/5 p-6 rounded-lg border border-gold/20"
            >
              <div className="text-gold mb-4">{event.icon}</div>
              <h3 className="text-xl font-display text-gold mb-2">{event.title}</h3>
              <p className="text-light/80">{event.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="bg-light/5 rounded-lg p-8 border border-gold/20">
          <h2 className="text-3xl font-display text-gold mb-6">Repertorio</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-display text-gold mb-4">Música Contemporánea</h3>
              <ul className="space-y-2 text-light/80">
                <li>• Pop & Charts actuales</li>
                <li>• House & Deep House</li>
                <li>• Lounge & Chill Out</li>
                <li>• Jazz Contemporáneo</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-display text-gold mb-4">Clásicos Atemporales</h3>
              <ul className="space-y-2 text-light/80">
                <li>• Jazz Standards</li>
                <li>• Bossa Nova</li>
                <li>• Baladas Clásicas</li>
                <li>• Música Latina</li>
              </ul>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Saxophone;