export default {
  nav: {
    home: 'Home',
    biography: 'Biography',
    events: 'Events',
    music: 'Music',
    gallery: 'Gallery',
    contact: 'Contact'
  },
  home: {
    hero: {
      subtitle: 'Saxophonist, DJ & Music Producer',
      cta: 'Discover my services'
    },
    services: {
      title: 'Services',
      saxophone: {
        title: 'Saxophonist',
        description: 'Live saxophone performances for weddings, corporate events, and exclusive parties.'
      },
      dj: {
        title: 'DJ',
        description: 'Unique sets combining electronic music with live saxophone performances.'
      },
      producer: {
        title: 'Music Producer',
        description: 'Electronic music production and collaborations with international artists.'
      }
    },
    about: {
      title: 'About me',
      description: 'With over a decade of experience, I blend the elegance of saxophone with the energy of electronic music to create unique experiences.',
      cta: 'Learn my story'
    }
  },
  biography: {
    title: 'Biography',
    content: 'Ramon Riera is a versatile artist who has captivated audiences worldwide with his unique fusion of saxophone and electronic music. With over a decade of experience in the music industry, Ramon has developed a distinctive style that combines the elegance of classical saxophone with contemporary electronic rhythms.\n\nTrained at the Conservatori del Liceu in Barcelona and having perfected his technique at prestigious international schools, Ramon has brought his music to some of Europe\'s most exclusive venues. His ability to adapt his performance to different contexts, from luxury weddings to high-end corporate events and international clubs, has made him one of the most sought-after artists in the premium events sector.\n\nAs a music producer, his tracks have been supported by some of the most respected DJs in the scene, and his releases on prominent labels have reached the charts on platforms like Beatport. His studio work is characterized by meticulous attention to detail and innovative integration of acoustic instruments in electronic productions.\n\nCurrently, Ramon divides his time between live performances, DJ sets with live saxophone, and music production in his personal studio, where he continues to explore new ways of fusing genres and creating unique musical experiences.'
  },
  events: {
    title: 'Upcoming Events',
    noEvents: 'No upcoming events scheduled'
  },
  music: {
    title: 'My Music',
    platforms: {
      spotify: 'Listen on Spotify',
      beatport: 'Buy on Beatport',
      traxsource: 'Buy on Traxsource'
    }
  },
  contact: {
    title: 'Contact',
    subtitle: 'Book your date or request information',
    form: {
      name: 'Name',
      email: 'Email',
      message: 'Message',
      submit: 'Send message',
      emailError: 'Please enter a valid email address',
      success: 'Message sent successfully',
      error: 'Error sending message. Please try again.',
      captchaError: 'Please complete the captcha'
    }
  }
};